import React from 'react'

function Blogs() {
  return (
    <div>
    Blogs
    {/* <FormBlog /> */}
    </div>
  )
}

export default Blogs